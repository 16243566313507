import validate from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45experiments_45global from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/middleware/01.experiments.global.ts";
import auth_45global from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/middleware/auth.global.ts";
import user_45status_45global from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/middleware/user-status.global.ts";
import _00_46redirectTrailingSlash from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/node_modules/@spilnu/core/dist/runtime/middleware/00.redirectTrailingSlash.global.js";
import tracking from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/node_modules/@spilnu/core/dist/runtime/middleware/tracking.global.js";
import tracking_45service from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/node_modules/@spilnu/core/dist/runtime/middleware/tracking-service.global.js";
import manifest_45route_45rule from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45experiments_45global,
  auth_45global,
  user_45status_45global,
  _00_46redirectTrailingSlash,
  tracking,
  tracking_45service,
  manifest_45route_45rule
]
export const namedMiddleware = {
  entrance: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/middleware/entrance.ts"),
  "require-auth": () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/node_modules/@spilnu/core/dist/runtime/middleware/require-auth.js"),
  "playgame-login": () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/node_modules/@spilnu/game/dist/runtime/middleware/playgame-login.js"),
  "recurring-consent": () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/node_modules/@spilnu/strapi/dist/runtime/middleware/recurring-consent.js")
}
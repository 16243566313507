
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as redirect_45assistantjp2X0aNB9eMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue?macro=true";
import { default as pendingnesQwqlHVwMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending.vue?macro=true";
import { default as affiliatejVaqTAFtrQMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/affiliate.vue?macro=true";
import { default as ansvarligt_45spiltlcF7CI0swMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue?macro=true";
import { default as redirect_45assistantjCy7CvbjlJMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue?macro=true";
import { default as bankosiVhMBGwtYMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/banko.vue?macro=true";
import { default as betingelserGRnNYJGs4QMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/betingelser.vue?macro=true";
import { default as bingo5ZPyESiAMuMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/bingo.vue?macro=true";
import { default as BlogMlGDoLCBdRMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/Blog.vue?macro=true";
import { default as bonuskodeRgeVYoFkGFMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/bonuskode.vue?macro=true";
import { default as casinocJl4Xud8UKMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/casino.vue?macro=true";
import { default as cookiepolitikumCDo4uEzaMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/cookiepolitik.vue?macro=true";
import { default as FAQNXjAZTnnghMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/FAQ.vue?macro=true";
import { default as _91slug_93U20rJA96poMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue?macro=true";
import { default as indexMxLxuzrvBzMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/index.vue?macro=true";
import { default as indexufZA7xDd1MMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/konkurrence/index.vue?macro=true";
import { default as beskeder6FyzYygtJLMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/konto/beskeder.vue?macro=true";
import { default as _91reportId_93tw2FLrf2DHMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/konto/brugerrapport/[reportId].vue?macro=true";
import { default as eidaUa2sJplwhMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/konto/eid.vue?macro=true";
import { default as kundeserviceIUFELB31YvMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/kundeservice.vue?macro=true";
import { default as mobil_45appDVGUwOQvI6Meta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/mobil-app.vue?macro=true";
import { default as om_45osFoFFl6ixXiMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/om-os.vue?macro=true";
import { default as loginptlgPEtsJTMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/playgame/login.vue?macro=true";
import { default as presseBPkaSaexHuMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/presse.vue?macro=true";
import { default as privatlivspolitikoTSB4TYG5dMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/privatlivspolitik.vue?macro=true";
import { default as signupcyFNdhq76ZMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/signup.vue?macro=true";
import { default as gemte_45spilyA7XRnI4ckMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/spilleautomater/gemte-spil.vue?macro=true";
import { default as indexKDkgAwhPiBMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/spilleautomater/index.vue?macro=true";
import { default as spiloversigtRufJsu4yLLMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/spiloversigt.vue?macro=true";
import { default as _91seoName_93TLQewWDtadMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/spilside/[seoName].vue?macro=true";
import { default as tvcqoswkyJ4mMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/tv.vue?macro=true";
import { default as vindereRXMCdpr8WwMeta } from "/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/vindere.vue?macro=true";
export default [
  {
    name: "redirect-assistant",
    path: "/payments/redirect-assistant",
    meta: redirect_45assistantjp2X0aNB9eMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue")
  },
  {
    name: "pending",
    path: "/payments/pending",
    meta: pendingnesQwqlHVwMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending.vue")
  },
  {
    name: "affiliate",
    path: "/affiliate",
    meta: affiliatejVaqTAFtrQMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/affiliate.vue")
  },
  {
    name: "ansvarligt-spil",
    path: "/ansvarligt-spil",
    meta: ansvarligt_45spiltlcF7CI0swMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue")
  },
  {
    name: "app-redirect-assistant",
    path: "/app/redirect-assistant",
    meta: redirect_45assistantjCy7CvbjlJMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue")
  },
  {
    name: "banko",
    path: "/banko",
    meta: bankosiVhMBGwtYMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/banko.vue")
  },
  {
    name: "betingelser",
    path: "/betingelser",
    meta: betingelserGRnNYJGs4QMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/betingelser.vue")
  },
  {
    name: "bingo",
    path: "/bingo",
    meta: bingo5ZPyESiAMuMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/bingo.vue")
  },
  {
    name: "Blog",
    path: "/Blog",
    meta: BlogMlGDoLCBdRMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/Blog.vue")
  },
  {
    name: "bonuskode",
    path: "/bonuskode",
    meta: bonuskodeRgeVYoFkGFMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/bonuskode.vue")
  },
  {
    name: "casino",
    path: "/casino",
    meta: casinocJl4Xud8UKMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/casino.vue")
  },
  {
    name: "cookiepolitik",
    path: "/cookiepolitik",
    meta: cookiepolitikumCDo4uEzaMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/cookiepolitik.vue")
  },
  {
    name: "FAQ",
    path: "/FAQ",
    meta: FAQNXjAZTnnghMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/FAQ.vue")
  },
  {
    name: "games-entrance-slug",
    path: "/games/entrance/:slug()",
    meta: _91slug_93U20rJA96poMeta || {},
    alias: ["/spil/:slug","/spilleautomater/entrance/:slug"],
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexMxLxuzrvBzMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/index.vue")
  },
  {
    name: "konkurrence",
    path: "/konkurrence",
    meta: indexufZA7xDd1MMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/konkurrence/index.vue")
  },
  {
    name: "konto-beskeder",
    path: "/konto/beskeder",
    meta: beskeder6FyzYygtJLMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/konto/beskeder.vue")
  },
  {
    name: "konto-brugerrapport-reportId",
    path: "/konto/brugerrapport/:reportId()",
    meta: _91reportId_93tw2FLrf2DHMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/konto/brugerrapport/[reportId].vue")
  },
  {
    name: "konto-eid",
    path: "/konto/eid",
    meta: eidaUa2sJplwhMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/konto/eid.vue")
  },
  {
    name: "kundeservice",
    path: "/kundeservice",
    meta: kundeserviceIUFELB31YvMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/kundeservice.vue")
  },
  {
    name: "mobil-app",
    path: "/mobil-app",
    meta: mobil_45appDVGUwOQvI6Meta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/mobil-app.vue")
  },
  {
    name: "om-os",
    path: "/om-os",
    meta: om_45osFoFFl6ixXiMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/om-os.vue")
  },
  {
    name: "playgame-login",
    path: "/playgame/login",
    meta: loginptlgPEtsJTMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/playgame/login.vue")
  },
  {
    name: "presse",
    path: "/presse",
    meta: presseBPkaSaexHuMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/presse.vue")
  },
  {
    name: "privatlivspolitik",
    path: "/privatlivspolitik",
    meta: privatlivspolitikoTSB4TYG5dMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/privatlivspolitik.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupcyFNdhq76ZMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/signup.vue")
  },
  {
    name: "spilleautomater-gemte-spil",
    path: "/spilleautomater/gemte-spil",
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/spilleautomater/gemte-spil.vue")
  },
  {
    name: "spilleautomater",
    path: "/spilleautomater",
    meta: indexKDkgAwhPiBMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/spilleautomater/index.vue")
  },
  {
    name: "spiloversigt",
    path: "/spiloversigt",
    meta: spiloversigtRufJsu4yLLMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/spiloversigt.vue")
  },
  {
    name: "spilside-seoName",
    path: "/spilside/:seoName()",
    meta: _91seoName_93TLQewWDtadMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/spilside/[seoName].vue")
  },
  {
    name: "tv",
    path: "/tv",
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/tv.vue")
  },
  {
    name: "vindere",
    path: "/vindere",
    meta: vindereRXMCdpr8WwMeta || {},
    component: () => import("/data/gitlab-runner/builds/i29m3Hdi/0/spilnu/frontend/spilnu/pages/vindere.vue")
  }
]
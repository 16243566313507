
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as redirect_45assistantwjRFwfUMprMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue?macro=true";
import { default as pendingqtW3EC6XR4Meta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending.vue?macro=true";
import { default as affiliatehH3KbAmlf0Meta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/affiliate.vue?macro=true";
import { default as ansvarligt_45spilMcFvmvZCN9Meta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue?macro=true";
import { default as redirect_45assistantlAwsJ2hnuTMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue?macro=true";
import { default as bankouCv2zmRab0Meta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/banko.vue?macro=true";
import { default as betingelserm48PCiQXy8Meta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/betingelser.vue?macro=true";
import { default as bingo3s0NC0ihbVMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/bingo.vue?macro=true";
import { default as Blog7LZaX6kPoAMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/Blog.vue?macro=true";
import { default as bonuskodea4RKILDdUmMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/bonuskode.vue?macro=true";
import { default as casinovcqSPWRozyMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/casino.vue?macro=true";
import { default as cookiepolitikrVWhXqpbOJMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/cookiepolitik.vue?macro=true";
import { default as FAQvNbXrx34bIMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/FAQ.vue?macro=true";
import { default as _91slug_9311aqPvnA2RMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue?macro=true";
import { default as indexq0uCJ89K0hMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/index.vue?macro=true";
import { default as indexLmiz9bZdrUMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/konkurrence/index.vue?macro=true";
import { default as beskederXBioVbX8CuMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/konto/beskeder.vue?macro=true";
import { default as _91reportId_93lLxEcVGrKCMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/konto/brugerrapport/[reportId].vue?macro=true";
import { default as eid94lxwWX3dRMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/konto/eid.vue?macro=true";
import { default as kundeservicenQZ4EY09vCMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/kundeservice.vue?macro=true";
import { default as mobil_45appVwDKAPeQlXMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/mobil-app.vue?macro=true";
import { default as om_45os7EWcyl3titMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/om-os.vue?macro=true";
import { default as loginkbYwtMYXdsMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/playgame/login.vue?macro=true";
import { default as presse6RSdpcWyYsMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/presse.vue?macro=true";
import { default as privatlivspolitikEzJVcpPjHHMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/privatlivspolitik.vue?macro=true";
import { default as signupPg1oxFbwhtMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/signup.vue?macro=true";
import { default as gemte_45spilUGyEMB9YraMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/spilleautomater/gemte-spil.vue?macro=true";
import { default as indexxhjIaXDISSMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/spilleautomater/index.vue?macro=true";
import { default as spiloversigtNSlExZfbXGMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/spiloversigt.vue?macro=true";
import { default as _91seoName_93HEa4H5Dw0lMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/spilside/[seoName].vue?macro=true";
import { default as tvXeOIE6D6YsMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/tv.vue?macro=true";
import { default as vinderem23BCrlxlLMeta } from "/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/vindere.vue?macro=true";
export default [
  {
    name: "redirect-assistant",
    path: "/payments/redirect-assistant",
    meta: redirect_45assistantwjRFwfUMprMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/redirect-assistant.vue")
  },
  {
    name: "pending",
    path: "/payments/pending",
    meta: pendingqtW3EC6XR4Meta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/node_modules/@spilnu/payments/dist/runtime/pages/pending.vue")
  },
  {
    name: "affiliate",
    path: "/affiliate",
    meta: affiliatehH3KbAmlf0Meta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/affiliate.vue")
  },
  {
    name: "ansvarligt-spil",
    path: "/ansvarligt-spil",
    meta: ansvarligt_45spilMcFvmvZCN9Meta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/ansvarligt-spil.vue")
  },
  {
    name: "app-redirect-assistant",
    path: "/app/redirect-assistant",
    meta: redirect_45assistantlAwsJ2hnuTMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/app/redirect-assistant.vue")
  },
  {
    name: "banko",
    path: "/banko",
    meta: bankouCv2zmRab0Meta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/banko.vue")
  },
  {
    name: "betingelser",
    path: "/betingelser",
    meta: betingelserm48PCiQXy8Meta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/betingelser.vue")
  },
  {
    name: "bingo",
    path: "/bingo",
    meta: bingo3s0NC0ihbVMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/bingo.vue")
  },
  {
    name: "Blog",
    path: "/Blog",
    meta: Blog7LZaX6kPoAMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/Blog.vue")
  },
  {
    name: "bonuskode",
    path: "/bonuskode",
    meta: bonuskodea4RKILDdUmMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/bonuskode.vue")
  },
  {
    name: "casino",
    path: "/casino",
    meta: casinovcqSPWRozyMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/casino.vue")
  },
  {
    name: "cookiepolitik",
    path: "/cookiepolitik",
    meta: cookiepolitikrVWhXqpbOJMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/cookiepolitik.vue")
  },
  {
    name: "FAQ",
    path: "/FAQ",
    meta: FAQvNbXrx34bIMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/FAQ.vue")
  },
  {
    name: "games-entrance-slug",
    path: "/games/entrance/:slug()",
    meta: _91slug_9311aqPvnA2RMeta || {},
    alias: ["/spil/:slug","/spilleautomater/entrance/:slug"],
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/games/entrance/[slug].vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexq0uCJ89K0hMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/index.vue")
  },
  {
    name: "konkurrence",
    path: "/konkurrence",
    meta: indexLmiz9bZdrUMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/konkurrence/index.vue")
  },
  {
    name: "konto-beskeder",
    path: "/konto/beskeder",
    meta: beskederXBioVbX8CuMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/konto/beskeder.vue")
  },
  {
    name: "konto-brugerrapport-reportId",
    path: "/konto/brugerrapport/:reportId()",
    meta: _91reportId_93lLxEcVGrKCMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/konto/brugerrapport/[reportId].vue")
  },
  {
    name: "konto-eid",
    path: "/konto/eid",
    meta: eid94lxwWX3dRMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/konto/eid.vue")
  },
  {
    name: "kundeservice",
    path: "/kundeservice",
    meta: kundeservicenQZ4EY09vCMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/kundeservice.vue")
  },
  {
    name: "mobil-app",
    path: "/mobil-app",
    meta: mobil_45appVwDKAPeQlXMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/mobil-app.vue")
  },
  {
    name: "om-os",
    path: "/om-os",
    meta: om_45os7EWcyl3titMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/om-os.vue")
  },
  {
    name: "playgame-login",
    path: "/playgame/login",
    meta: loginkbYwtMYXdsMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/playgame/login.vue")
  },
  {
    name: "presse",
    path: "/presse",
    meta: presse6RSdpcWyYsMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/presse.vue")
  },
  {
    name: "privatlivspolitik",
    path: "/privatlivspolitik",
    meta: privatlivspolitikEzJVcpPjHHMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/privatlivspolitik.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupPg1oxFbwhtMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/signup.vue")
  },
  {
    name: "spilleautomater-gemte-spil",
    path: "/spilleautomater/gemte-spil",
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/spilleautomater/gemte-spil.vue")
  },
  {
    name: "spilleautomater",
    path: "/spilleautomater",
    meta: indexxhjIaXDISSMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/spilleautomater/index.vue")
  },
  {
    name: "spiloversigt",
    path: "/spiloversigt",
    meta: spiloversigtNSlExZfbXGMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/spiloversigt.vue")
  },
  {
    name: "spilside-seoName",
    path: "/spilside/:seoName()",
    meta: _91seoName_93HEa4H5Dw0lMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/spilside/[seoName].vue")
  },
  {
    name: "tv",
    path: "/tv",
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/tv.vue")
  },
  {
    name: "vindere",
    path: "/vindere",
    meta: vinderem23BCrlxlLMeta || {},
    component: () => import("/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/pages/vindere.vue")
  }
]
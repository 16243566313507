export const useRules = () => {
    function required(value?: any) {
        return !!value || 'Skal udfyldes'
    }

    /**
     * Validate a string is an email.
     *
     * Regex is sourced from this SO discussion: https://stackoverflow.com/a/46181
     *
     * @param value An email
     * @returns true or a string
     */
    function email(value?: string) {
        if (!value) return true

        return !!value.match(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/) || 'Ugyldig e-mail-addresse'
    }

    function fullName(value?: string) {
        if (!value) return true

        // Check for numbers
        if (value.match(/\d+/g)) return 'Må ikke indeholde tal'

        // Check for special characters
        if (!value.match(/^([^!"#€%&(){}_=?/[\]`´.,*@º¨:;^]*)$/)) return 'Må ikke indeholde specialtegn'

        return true
    }

    function password(value?: string) {
        if (!value) return true

        const validationObject = passwordValidation(value)

        return validationObject.isValid || 'Adgangskoden skal opfylde kravene herunder'
    }

    function passwordOld(value?: string) {
        if (!value) return true

        if (value.length < 8) return 'Skal være mindst 8 tegn langt'

        return true
    }

    function ssn(value?: string) {
        if (!value) return true

        if (value.length < 11) return 'Ugyldigt CPR-nummer. Brug korrekt format (ddmmyy-xxxx)'

        return true
    }

    function phone(value?: string) {
        const strippedNo = value?.replace('+45', '').replace(/\s/g, '')

        return strippedNo?.length === 8 || 'Skal være et gyldigt telefonnummer'
    }

    return { required, email, fullName, password, passwordOld, ssn, phone }
}

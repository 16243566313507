
import * as cloudflareRuntime$jxOPbS7twX from '/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'
import * as ipxRuntime$JFLu8yJs67 from '/data/gitlab-runner/builds/ZyvFkp3mK/1/spilnu/frontend/spilnu/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 360,
    "sm": 414,
    "md": 768,
    "lg": 1024,
    "xl": 1366,
    "xxl": 1920,
    "2xl": 1536
  },
  "presets": {
    "gameservice": {
      "baseURL": "https://media.platego.io"
    },
    "heroDev": {
      "baseURL": "https://stage.spilnu.dk"
    },
    "heroStage": {
      "baseURL": "https://stage.spilnu.dk"
    },
    "heroProd": {
      "baseURL": "https://www.spilnu.dk"
    },
    "local": {
      "baseURL": "/"
    }
  },
  "provider": "ipx",
  "domains": [
    "media.platego.io",
    "stage.spilnu.dk",
    "www.spilnu.dk"
  ],
  "alias": {},
  "densities": [
    1,
    2,
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudflare']: { provider: cloudflareRuntime$jxOPbS7twX, defaults: {"baseURL":"https://media.platego.io","modifiers":{"format":"webp","quality":"70"}} },
  ['ipx']: { provider: ipxRuntime$JFLu8yJs67, defaults: {} }
}
        